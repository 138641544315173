a {
  font-family: "Roboto Mono", monospace;
}

.Menu {
  margin: 0 20px;
}

.menu-link {
  text-decoration: none;
}

.logo {
  width: 3%;
  margin-right: 20px;
}
