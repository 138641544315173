section {
  margin: 50px 150px;
  padding: 50px 40px;
  text-align: center;
  background-color: #f8f9fa;
  border-radius: 15px;
  font-family: "Roboto Mono", monospace;
  font-weight: 300;
}

section h2 {
  margin-bottom: 30px;
}

section p {
  margin-top: 20px;
}

section h4 {
  margin: 20px 0;
}

.titel h1,
.titel h2 {
  font-size: 64px;
  color: #fff;
  font-family: "Roboto Mono", monospace;
}

.titel h1 {
  font-size: 64px;
}
.titel h2 {
  font-size: 30px;
}

.titel {
  margin: 100px 0;
}

@media (max-width: 992px) {
  .App h1 {
    font-size: 40px;
  }
  .App h2 {
    font-size: 22px;
  }

  section {
    margin: 30px 30px;
    padding: 30px 10px;
  }
}

@media (max-width: 450px) {
  .App h1 {
    font-size: 30px;
  }
  .App h2 {
    font-size: 22px;
  }
}
