.background-image {
  margin: auto;
  height: 20px;
  max-width: 100%;
}

.background-image .salami {
  max-width: 100%;
  background-size: cover;
  padding-top: 50%;
}

@media (max-width: 450px) {
  .background-image .salami {
    padding-top: 72%;
  }
}
