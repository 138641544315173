.leaflet-container {
  height: 400px;
  width: 100%;
}

@media (max-width: 992px) {
  .Contact h2 {
    font-size: 22px;
  }

  .Contact h5 {
    font-size: 13px;
  }
}
